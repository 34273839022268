import { postBodyRequest, getRequest } from '@/utils/axios'

export function page (carrierId, params) {
  return postBodyRequest(`/carrier/carrier/line/${carrierId}/page`, params)
}

export function add (carrierId, params) {
  return postBodyRequest(`/carrier/carrier/line/${carrierId}/add`, params)
}
export function edit (carrierId, params) {
  return postBodyRequest(`/carrier/carrier/line/${carrierId}/edit`, params)
}
export function delet (addressId) {
  return postBodyRequest(`/carrier/carrier/line/${addressId}/delete`, {})
}

export function detail (lineId) {
  return getRequest(`/carrier/carrier/line/${lineId}`, {})
}

export function optimal (carrierId, params) {
  return postBodyRequest(`/carrier/carrier/line/${carrierId}/optimal`, params)
}
export function optimalEdit (params) {
  return postBodyRequest('/carrier/carrier/line/optimal/edit', params)
}

export function optimalPage (params) {
  return postBodyRequest('/carrier/carrier/line/optimal/page', params)
}

export function strongholdOptimalPage (params) {
  return postBodyRequest('/carrier/carrier/line/stronghold/optimal/page', params)
}

export function strongholdOptimalLineNamePage (params) {
  return postBodyRequest('/carrier/carrier/line/stronghold/optimal/lineName/page', params)
}
//复制线路
export function copyLine (params) {
  return postBodyRequest('/carrier/carrier/line/copy-carrier-cine', params)
}