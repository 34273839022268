<template>
  <div>
    <!-- 搜索模块 -->
    <div>
      <a-form layout="inline">
        <a-form-item label="">
          <a-input v-model:value="searchForm.departure" placeholder="派单人"/>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.settlementStatus" style="width:150px" placeholder="结算状态">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="2">已结算</a-select-option>
            <a-select-option :value="0">未结算</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.vinNo" placeholder="车牌号"/>
        </a-form-item>
        <a-form-item label="">
          <div class="operate-btn">
            <a-button class="header-search-btn" @click="searchData" :loading="loading">搜索</a-button>
            <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
            <a-button class="header-derive-btn" @click="deriveOperate" :loading="loading">导出选中</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <div style="margin: 10px 0">
      <span style="margin-right:15px">共<span style="color:#0066CC">{{ pagination.total }}</span>个任务</span>
      <span>总运费：<span style="color:#0066CC">￥{{ totalFreight }}</span></span>
    </div>
    <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns"
      :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination" :loading="loading"
      @change="tableChange">
    </a-table>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { getPage, deriveData, getTotalFreight } from '@/api/carrier/task'
import { message } from 'ant-design-vue'

export default {
  props: {
    carrierId: String
  },
  setup (props) {
    const state = reactive({
      loading: false,
      totalFreight: 0,
      searchForm: {
        carrierId: '',
        departure: '',
        settlementStatus: '',
        vinNo: ''
      },
      mirrorSearchForm: {},
      listData: [],
      selectedRowKeys: [],
      columns: [
        {
          title: '订单号',
          dataIndex: 'orderId',
          align: 'center'
        },
        {
          title: '车辆',
          dataIndex: 'vinNo',
          align: 'center'
        },
        {
          title: '结算状态',
          dataIndex: 'settlementStatus.label',
          align: 'center'
        },
        {
          title: '派单人',
          dataIndex: 'departure',
          align: 'center'
        }, {
          title: '派单时间',
          dataIndex: 'departureTime',
          align: 'center'
        },
        {
          title: '派单价格',
          dataIndex: 'settableTotalAmt',
          align: 'center'
        },
        {
          title: '任务线路',
          dataIndex: 'transLine',
          align: 'center'
        }
        // {
        //   title: '任务备注',
        //   dataIndex: 'taskRemark',
        //   width: '20%',
        //   align: 'center'
        // }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      if (props.carrierId) state.searchForm.carrierId = props.carrierId
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const getFreight = () => {
      getTotalFreight(state.searchForm).then(res => {
        if (res.code === 10000) {
          state.totalFreight = res.data
        }
      })
    }
    const loadData = () => {
      state.loading = true
      setTimeout(() => {
        if (state.searchForm.carrierId) {
          getPage({
            ...state.searchForm,
            current: state.pagination.current,
            size: state.pagination.size
          }).then(res => {
            if (res.code === 10000) {
              state.pagination.total = res.data.total
              state.listData = res.data.records
              getFreight()
            }
          }).finally(() => {
            state.loading = false
          })
        }
      }, 100)
    }
    const onSelectChange = (e, v) => {
      state.selectedRowKeys = e
    }
    const tableChange = page => {
      state.pagination = page
      loadData()
    }
    const searchData = () => {
      state.pagination.current = 1
      state.listData = []
      loadData()
    }
    const resetData = () => {
      state.pagination.current = 1
      state.listData = []
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      loadData()
    }
    const deriveOperate = () => {
      if (state.selectedRowKeys.length > 0) {
        deriveData({
          carrierId: state.searchForm.carrierId,
          totvIds: state.selectedRowKeys
        }).then(res => {
          state.selectedRowKeys = []
        })
      }else message.error('请先选择需要导出的数据')
    }

    return {
      ...toRefs(state),
      onSelectChange,
      deriveOperate,
      tableChange,
      searchData,
      resetData,
      loadData
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
