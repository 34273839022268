<template>
  <div>
    <div style="display:flex;justify-content:space-between;margin:10px 0">
      <a-form layout="inline">
        <a-form-item label="">
          <a-input v-model:value="searchForm.name" placeholder="司机姓名"/>
        </a-form-item>
        <a-form-item>
          <a-button :loading="loading" @click="search">搜索</a-button>
        </a-form-item>
      </a-form>
      <a-button @click="addShow = true">+添加司机</a-button>
    </div>
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #detail="{record}">
        <div>
          <a @click="detail(record)">查看司机资料图片</a>
        </div>
      </template>

      <template #operation="{record}">
        <div style="display:flex;justify-content: space-around;">
          <a @click="onDetail(record)">编辑</a>
          <a @click="onDetailImg(record)">编辑图片</a>
        </div>
      </template>
    </a-table>
    <a-modal :width="750" v-model:visible="addShow" footer="" @cancel="onNoAddForm" destroyOnClose>
      <template #title><span style="font-size:20px">{{editShow===0?'新增司机':'编辑司机'}}</span></template>
      <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
        <a-row :gutter="[24,12]">
          <a-col :span="12" v-if="editShow!=2">
            <a-form-item label="司机姓名">
              <a-input v-model:value="addForm.name" placeholder="请填写司机姓名" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=2">
            <a-form-item label="司机电话" name="mobile">
              <a-input v-model:value="addForm.mobile" placeholder="请填写司机电话" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=2">
            <a-form-item label="身份证号">
              <a-input v-model:value="addForm.idNumber" placeholder="请填写司机身份证号" />
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=2">
          </a-col>
          <a-col :span="12" v-if="editShow!=1">
            <a-form-item label="驾驶证" @click="flag=1">
              <a-upload v-model:file-list="drivingLicense" name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
                <img v-if="addForm.driverLicenseUrl" style="width:150px;height:150px" :src="addForm.driverLicenseUrl" />
                <div v-else>
                  <loading-outlined v-if="drivingLoading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="12" v-if="editShow!=1">
            <a-form-item label="货运资格从业证" @click="flag=2">
              <a-upload v-model:file-list="qualificationLincense" name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
                <img v-if="addForm.freightCertificateUrl" style="width:150px;height:150px" :src="addForm.freightCertificateUrl" />
                <div v-else>
                  <loading-outlined v-if="qualificationLoading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div style="display:flex;justify-content: flex-end;margin-top:15px">
        <a-button size="large" style="margin-right:15px" @click="addShow=false">取消</a-button>
        <a-button size="large" class="searchLoading-button" @click="addData">{{editShow===0?'新增':'保存'}}</a-button>
      </div>
    </a-modal>
    <a-modal v-model:visible="detailShow" footer="" :height="200" destroyOnClose>
      <template #title>
        <span style="font-size:20px">司机资料图片</span>
      </template>
      <div></div>
      <ImgDetail :detailImg="detailImg" ref="_imgDetail" />
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { mobileValidate, idNumberValidate } from '@/utils/validate'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { fileUploadPublic as fileUpload } from '@/api/global'
import { getPage, addDriver, driverDetail, deleteDriver, edit, editPhoto, getCarrierIdCarrierDriverId } from '@/api/carrier/driverList'
import { message } from 'ant-design-vue'
import ImgDetail from '../imgDetail.vue'

export default {
  components: {
    LoadingOutlined,
    PlusOutlined,
    ImgDetail
  },
  props: { carrierId: String },
  setup (props) {
    const _imgDetail = ref()
    const formRef = ref()
    const state = reactive({
      carrierId: props.carrierId,
      flag: 0,
      loading: false,
      addShow: false,
      detailShow: false,
      drivingLoading: false,
      qualificationLoading: false,
      empty: {},
      searchForm: {
        name: ''
      },
      listData: [],
      detailImg: [],
      drivingLicense: [],
      qualificationLincense: [],
      editShow: 0,
      addForm: {
        name: '',
        mobile: '',
        idNumber: '',
        driverLicenseUrl: '',
        driverLicense: '',
        freightCertificateUrl: '',
        freightCertificate: ''
      },
      columns: [
        {
          title: '司机姓名',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: '司机电话',
          dataIndex: 'mobile',
          align: 'center'
        },
        {
          title: '身份证号',
          dataIndex: 'idNumber',
          align: 'center'
        },
        {
          title: '',
          dataIndex: 'detail',
          align: 'center',
          slots: {
            customRender: 'detail'
          }
        },
        {
          title: '',
          dataIndex: 'operation',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      rules: {
        mobile: [{ required: false, message: '请填写司机电话', trigger: 'blur' }, { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }],
        idNumber: [{ required: false, message: '请填写司机身份证号', trigger: 'blur' }, { pattern: idNumberValidate, message: '请填写正确的身份证号码', trigger: 'blur' }]
      }
    })
    const loadData = () => {
      state.loading = true
      getPage({ carrierId: state.carrierId, current: state.current, name: state.searchForm.name })
        .then(res => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.pagination.total = res.data.total
          }
        }).catch(err => { console.log(err) })
        .finally(() => { state.loading = false })
    }
    const search = () => {
       state.pagination.current = 1
      loadData()
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    onMounted(() => {
      // loadData()
      state.empty = JSON.parse(JSON.stringify(state.addForm))
    })
    const deleteData = record => {
      deleteDriver({ carrierDriverId: record.id })
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
          }
        }).catch(err => { console.log(err) })
    }
    // 图片上传
    const handleChange = info => {
      const formData = new FormData()
      switch (state.flag) {
        case 1:
          state.drivingLoading = true
          formData.append('file', state.drivingLicense[0])
          fileUpload(formData).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                state.addForm.driverLicenseUrl = res.data.url
                state.addForm.driverLicense = res.data.id
                state.drivingLoading = false
                state.drivingLicense = []
              }
            }
          })
          break
        case 2:
          state.qualificationLoading = true
          formData.append('file', state.qualificationLincense[0])
          fileUpload(formData).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                state.addForm.freightCertificateUrl = res.data.url
                state.addForm.freightCertificate = res.data.id
                state.qualificationLoading = false
                state.qualificationLincense = []
              }
            }
          })
          break
      }
    }

    const beforeUpload = file => {
      switch (state.flag) {
        case 1:
          state.drivingLicense.push(file)
          break
        case 2:
          state.qualificationLincense.push(file)
          break
      }
      return false
    }
    const detail = record => {
      driverDetail(record.carrierId, record.id)
        .then(res => {
          if (res.code === 10000) {
            if (res.data.driverLicenseUrl === null) {
              res.data.driverLicenseUrl = {}
            }
            if (res.data.freightCertificateUrl === null) {
              res.data.freightCertificateUrl = {}
            }
            state.detailImg = [{
              title: '驾驶证',
              url: res.data.driverLicenseUrl.previewUrl,
              previewUrl: res.data.driverLicenseUrl.url
            }, {
              title: '从业资格证',
              url: res.data.freightCertificateUrl.previewUrl,
              previewUrl: res.data.freightCertificateUrl.url
            }]
            setTimeout(() => {
              _imgDetail.value.loadData()
            }, 100)
            state.detailShow = true
          }
        }).catch(err => { console.log(err) })
    }
    const onAdd = () => {
      addDriver({ ...state.addForm, carrierId: state.carrierId })
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
          }
        }).catch(err => { console.log(err) }).finally(() => { state.addShow = false })
    }
    const addData = () => {
      state.addForm.driverLicenseUrl = null
      state.addForm.freightCertificateUrl = null
      console.log('state.editShow', state.editShow)

      if (state.editShow === 1) {
        formRef.value.validate().then(() => {
          onEdit()
        })
      } else if (state.editShow === 2) {
        onEditPhoto()
      } else {
        formRef.value.validate().then(() => {
          onAdd()
        })
      }
    }
    const onEdit = () => {
      edit({ ...state.addForm })
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            onNoAddForm()
            loadData()
          }
        }).catch(err => { console.log(err) }).finally(() => { state.addShow = false })
    }
    const onEditPhoto = () => {
      const driverPhoto = {
        driverId: state.addForm.id,
        driverLicense: state.addForm.driverLicense,
        freightCertificate: state.addForm.freightCertificate
      }
      editPhoto(driverPhoto)
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            onNoAddForm()
            loadData()
          }
        }).catch(err => { console.log(err) }).finally(() => { state.addShow = false })
    }

    const onDetail = (e) => {
      state.addShow = true
      state.editShow = 1
      getCarrierIdCarrierDriverId(e.carrierId, e.id)
        .then(res => {
          if (res.code === 10000) {
            state.addForm = res.data
            state.addForm.id = e.id
          }
        }).catch(err => { console.log(err) })
    }
    const onDetailImg = (e) => {
      onImgDetail(e)
      state.editShow = 2
      state.addShow = true
      state.addForm.id = e.id
      state.addForm.carrierId = e.carrierId
    }
    const onImgDetail = record => {
      driverDetail(record.carrierId, record.id)
        .then(res => {
          if (res.code === 10000) {
            state.addForm.driverLicenseUrl = res.data.driverLicenseUrl.previewUrl
            state.addForm.freightCertificateUrl = res.data.freightCertificateUrl.previewUrl

            state.addForm.driverLicense = res.data.driverLicenseUrl.id
            state.addForm.freightCertificate = res.data.freightCertificateUrl.id
          }
        }).catch(err => { console.log(err) })
    }
    const onNoAddForm = () => {
      state.addShow = false
      state.editShow = 0
      state.addForm = {
        name: '',
        mobile: '',
        idNumber: '',
        driverLicenseUrl: '',
        driverLicense: '',
        freightCertificateUrl: '',
        freightCertificate: ''
      }
    }

    return {
      ...toRefs(state),
      formRef,
      detail,
      addData,
      loadData,
      deleteData,
      _imgDetail,
      handleChange,
      beforeUpload,
      handleTableChange,
      search,
      onDetail,
      onDetailImg,
      onImgDetail,
      onNoAddForm,
      onEditPhoto
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>
