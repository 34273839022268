<template>
  <div>
    <div style="display:flex;justify-content:flex-end;margin:10px 0">
      <a-button @click="addShow = true">+新增员工</a-button>
    </div>
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #operation="{ record }">
        <a-space>
          <a-popconfirm title="确认删除?" ok-text="确认" cancel-text="取消" @confirm="deleteData(record)">
            <a style="color:#A30014">删除</a>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
    <a-modal :width="750" title="绑定员工" @ok="onAdd" v-model:visible="addShow" @cancel="onDistributionShowNo">
      <a-form layout="vertical" ref="_addRef" :model="addForm" :rules="rules">
        <a-form-item label="员工" name="name">
          <a-select style="width: 100%" v-model:value="addForm.name" class="select" show-search :filter-option="false"
            :not-found-content="fetching ? '起码输入2个中文进行搜索' : '没有相关数据'" @select="onDistributionCustomer"
            @search="handleSearch" @change="onDistributionCustomer">
            <a-select-option v-for="item  in employeeArray" :value="item.name" :key="item.id">
              <a-row>
                <a-col :span="8">
                  {{ item.name }}
                </a-col>
                <a-col :span="8">
                  {{ item.orgName }}
                </a-col>
                <a-col :span="8">
                  {{ item.mobile }}
                </a-col>
              </a-row>
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import { page, add, deleteId } from '@/api/carrier/staff'
import { message } from 'ant-design-vue'
import { list } from '@/api/system/employee'
// import OptionalMap from '@/views/components/optionalMap'
export default {
  props: {
    carrierId: String
  },
  setup (props, context) {
    const _addRef = ref(null)
    const state = reactive({
      carrier: props.carrierId,
      listData: [],
      employeeArray: [],
      pagination: {},
      addForm: {
        name: ''
      },
      editForm: {},
      timer: null,
      loading: false,
      addShow: false,
      editShow: false,
      columns: [{
        title: '员工名称',
        dataIndex: 'empName',
        align: 'center'
      },
      {
        title: '添加人',
        dataIndex: 'operator',
        align: 'center'
      },
      {
        title: '添加时间',
        dataIndex: 'createTime',
        align: 'center'
      },
      {
        title: '操作',
        slots: {
          customRender: 'operation'
        }
      }
      ],
      rules: {
        name: {
          required: true,
          message: '请选择员工',
          trigger: 'change'
        }
      }
    })
    // 表格数据加载
    const loadData = () => {
      state.loading = true
      page(
        {
          carrierId: props.carrierId,
          current: state.pagination.current,
          size: state.pagination.pageSize
        }).then(res => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.pagination.current = res.data.current
            state.pagination.pageSize = res.data.size
            state.pagination.total = res.data.total
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          state.loading = false
        })
    }
    // 表格分页
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 新增
    const onAdd = () => {
      _addRef.value.validate()
        .then(() => {
          add({
            carrierId: props.carrierId,
            empName: state.addForm.name,
            employeeId: state.addForm.empAfterId
          }).then(res => {
            if (res.code === 10000) {
              state.addShow = false
              message.success(res.msg)
              state.addForm = {}
              loadData()
            }
          }).catch(err => {
            console.log(err)
          })
        })
    }
    // 取消绑定业务员
    const onDistributionShowNo = () => {
      state.addShow = false
      state.addForm.name = ''
      state.employeeArray = []
    }
    // 用户搜索
    const handleSearch = value => {
      if (value.length > 0 && value != '') {
        if (state.timer !== null) {
          clearTimeout(state.timer)
        }
        state.fetching = false
        shake(value)
      } else clearTimeout(state.timer)
    }
    const shake = (val) => {
      state.timer = setTimeout(() => {
        list({ name: val }).then((res) => {
          if (res.code === 10000) {
            state.employeeArray = res.data
            state.fetching = true
          }
        })
      }, 1000)
    }
    // 选择用户 赋值
    const onDistributionCustomer = (value, option) => {
      state.addForm.empAfterId = option.key
      state.addForm.name = value
      state.employeeArray = []
    }
    // 删除
    const deleteData = (e) => {
      deleteId({ id: e.id }).then(res => {
        message.info(res.msg)
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        loadData()
      })
    }
    return {
      ...toRefs(state),
      handleTableChange,
      loadData,
      onAdd,
      onDistributionShowNo,
      onDistributionCustomer,
      handleSearch,
      deleteData,
      _addRef
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>
