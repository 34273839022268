<template>
  <div>
    <div style="display:flex;justify-content:flex-end;margin:10px 0">
      <a-button v-show="editDisabled===false" @click="edit" :loading="loading" class="searchLoading-button">保存</a-button>
      <a-button @click="editDisabled = !editDisabled" style="margin-left:15px">{{editDisabled ? '编辑' : '取消'}}</a-button>
    </div>
    <a-form layout="vertical" ref="formRef" :model="data">
      <a-row :gutter="[24,12]">
        <a-col :span="12">
          <a-form-item label="银行账号">
            <a-input v-model:value="data.account" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="纳税人识别号">
            <a-input v-model:value="data.taxIdentNum" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="开户行">
            <a-input v-model:value="data.bank" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="开户行地址">
            <a-input v-model:value="data.address" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="财务对接人">
            <a-input v-model:value="data.financialPerson" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="发票类型">
            <a-select v-model:value="data.invoiceType.value" :disabled="editDisabled" @change="invoiceChange">
              <a-select-option :value="1">普通发票</a-select-option>
              <a-select-option :value="2">专用发票</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { getData, editData } from '@/api/carrier/financeData'
import { message } from 'ant-design-vue'

export default {
  props: { carrierId: String },
  setup (props) {
    const state = reactive({
      editDisabled: true,
      loading: false,
      carrierId: props.carrierId,
      data: {
        account: '',
        taxIdentNum: '',
        bank: '',
        address: '',
        financialPerson: '',
        invoiceType: { value: '' }
      }
    })
    const loadData = () => {
      state.editDisabled = true
      getData(state.carrierId)
        .then(res => {
          if (res.code === 10000) {
            state.data = res.data
            if (state.data.invoiceType === null || state.data.invoiceType === undefined) state.data.invoiceType = { value: '' }
          }
        }).catch(err => { console.log(err) })
    }
    onMounted(() => {
      // loadData()
    })
    const invoiceChange = (e, v) => {
      state.data.invoiceType = { label: v.children[0].children, value: v.value }
    }
    const edit = () => {
      state.loading = true
      editData(state.data)
        .then(res => {
          if (res.code === 10000) {
            state.loading = false
            state.editDisabled = true
            message.success(res.msg)
            loadData()
          }
        }).catch(err => { console.log(err) })
    }

    return {
      ...toRefs(state),
      edit,
      loadData,
      invoiceChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>
