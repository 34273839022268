<template>
  <div>
    <div class="detail-data">
      <div style="width:50%;margin-bottom:10px" v-for="item in imgArr" :key="item.title">
        <div>{{item.title}}</div>
        <a-image :width="100" @click="onClick(item)" :src="item.url" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
  props: { detailImg: Array },
  setup (props) {
    const state = reactive({
      imgArr: []
    })
    const loadData = () => {
      state.imgArr = props.detailImg
    }
    const onClick = (item) => {
      console.log('item', item)
      item.url = item.previewUrl
    }
    return {
      ...toRefs(state),
      loadData,
      onClick
    }
  }
}
</script>

<style lang="less" scoped>
@import './common.less';
.detail-data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
