<template>
  <div style="display: flex;justify-content: space-between;">
    <div class="new-score">
      <div style="display: flex;justify-content: space-between;">
        <h3 class="title-content" v-show="contentShow">{{ graderData.grader }} <span style="margin-left: 15px;">{{
          graderData.updateTime
        }}</span></h3>
        <div style="flex:1;text-align: right;" v-show="periodType?.value === 3 || periodType?.value === 4">
          <a-button class="addGrade-btn" @click="addGrade">+新增打分</a-button>
        </div>
      </div>
      <table class="table">
        <thead>
          <tr class="table-head">
            <td>类别</td>
            <td>指标及数据</td>
            <td>得分</td>
            <td>备注</td>
            <td>合计</td>
          </tr>
        </thead>
        <tbody v-if="tableArray.length > 0">
          <tr v-for="(item, index) in tableArray" :key="index">
            <td v-show="index <= 0" rowspan="4" style="text-align: center;">
              <div class="type-title" style="margin-bottom: 10px;">时效({{ graderData.prescription }}分)</div>
              <!-- <div style="font-size: 12px;" v-show="item.rateType?.value <= 14">承运总台量：xxx</div>
              <div style="font-size: 12px;" v-show="item.rateType?.value <= 14">超时台量：xx</div>
              <div style="font-size: 12px;" v-show="item.rateType?.value <= 14">准时率：xxx%</div> -->
            </td>
            <td v-show="index >= 4 && index < 5" rowspan="2" style="text-align: center;">
              <div class="type-title" style="margin-bottom: 10px;">质损({{ graderData.loss }}分)</div>
            </td>
            <td v-show="index >= 6 && index < 7" rowspan="3" style="text-align: center;">
              <div class="type-title" style="margin-bottom: 10px;">操作({{ graderData.operation }}分)</div>
            </td>
            <td v-show="index >= 9 && index < 10" rowspan="4" style="text-align: center;">
              <div class="type-title" style="margin-bottom: 10px;">服务({{ graderData.service }}分)</div>
            </td>
            <td v-show="item.rateType?.value <= 14">
              <span style="margin-right:15px">{{ item.rateType.label }}</span>
              <span style="margin-right:15px">总台量：{{ item.contentObj?.totalNum }}</span>
              <span style="margin-right:15px">超时台量：{{ item.contentObj?.timeoutNum }}</span>
              <span>准时率：{{ item.contentObj?.onTimeRate }}%</span>
            </td>
            <td v-show="item.rateType?.value === 21">
              <span style="margin-right:15px">{{ item.rateType.label }}</span>
              <span style="margin-right:15px">质损金额：￥{{ item.contentObj?.massLoss }}</span>
              <span>赔付金额：￥{{ item.contentObj?.lossIndemnity }}</span>
            </td>
            <td v-show="item.rateType?.value === 22">
              <!-- <span style="margin-right:15px">{{ item.rateType.label }}</span> -->
              <span style="margin-right:15px">发运总台量：{{ item.contentObj?.massLossTotalNum }}</span>
              <span style="margin-right:15px">质损台量：{{ item.contentObj?.massLossNum }} </span>
              <span>质损率：{{ item.contentObj?.massLossRate }}%</span>
            </td>
            <td v-show="item.rateType?.value > 22">{{ item.rateType.label }}</td>
            <td>
              <span v-show="contentShow">{{ item.score }}</span>
              <a-input-number v-show="!contentShow" placeholder="请填写" v-model:value="item.score" :min="0"
                @change="scoreChange(index)" />
            </td>
            <td style="width: 250px">
              <a-tooltip>
                <template #title>{{ item.remark }}</template>
                <div v-show="contentShow" class="remark-content">
                  <span>{{ item.remark }}</span>
                </div>
              </a-tooltip>
              <a-textarea v-show="!contentShow" v-model:value="item.remark" />
            </td>
            <td v-if="index <= 0" rowspan="4" class="amount-content">
              <span>{{ scoredata.prescription }}</span>
            </td>
            <td v-if="index >= 4 && index < 5" rowspan="2" class="amount-content">
              <span>{{ scoredata.loss }}</span>
            </td>
            <td v-if="index >= 6 && index < 7" rowspan="3" class="amount-content">
              <span>{{ scoredata.operation }}</span>
            </td>
            <td v-if="index >= 9 && index < 10" rowspan="4" class="amount-content">
              <span>{{ scoredata.service }}</span>
            </td>
          </tr>
          <tr>
            <td class="type-title">合计（100分）</td>
            <td colspan="4" style="font-weight: bold;color:#333">{{ scoredata.total }}</td>
          </tr>
        </tbody>
      </table>
      <div style="display: flex;justify-content: flex-end;margin-top: 10px;"
        v-show="periodType?.value === 3 || periodType?.value === 4">
        <a-button type="primary" v-if="!graderData.grader" v-show="!contentShow && !graderData.grader"
          @click="submitGrade" :loading="loading">提交分数</a-button>
        <div v-if="historylist.length > 0">
          <a-button type="primary" v-if="graderData.grader && rateId == historylist[0].rateId"
            v-show="!editSHow && !addShow" @click="editBtn">编辑</a-button>
          <a-button type="primary" v-if="graderData.grader" v-show="editSHow && !addShow" @click="submitGrade"
            :loading="loading">确认编辑</a-button>
        </div>
      </div>
    </div>
    <div class="history-score">
      <h3 class="title-content">历史评分记录
        <a-popover title="评分规则">
          <template #content>
            <p style="width: 300px; padding: 0 10px">85分以上  5星</p>
            <p style="width: 300px; padding: 0 10px">81-85分  4星</p>
            <p style="width: 300px; padding: 0 10px">76-80分  3星</p>
            <p style="width: 300px; padding: 0 10px">70-75分  2星</p>
            <p style="width: 300px; padding: 0 10px">70分以下  1星</p>
            评星结果低于3星，可选择将承运商转入淘汰期，发运时不可选择。
          </template>
          <b>查看</b>
        </a-popover>
      </h3>
      <div class="history-content">
        <div v-for="item in historylist" :key="item.rateId" v-show="historylist.length > 0">
          <div style="display: flex;justify-content: space-between;align-items: center;">
            <div style="display: flex;align-items: center;">
              <div style="margin-right:30px;width:100px">
                得分：{{ item.score }}
              </div>
              <div style="display: flex;">等级：<a-rate v-model:value="item.level" disabled /></div>
            </div>
            <img src="@/assets/images/u143.png" style="width: 20px;height: 20px;cursor: pointer;"
              @click="openDetail(item)" />
          </div>
          <div style="color:#ACADB0;font-size: 14px;font-weight: normal;">
            <span style="margin-right:30px">{{ item.grader }}</span>
            <span>{{ item.updateTime }}</span>
          </div>
          <a-divider />
        </div>
        <div style="display: flex;justify-content: center;align-items: center;">
          <a v-show="!loadShow && pagination.total > 0 && pagination.total > 10 && historylist.length < pagination.total"
            @click="loadMore">加载更多....</a>
          <a-spin v-show="loadShow && historylist.length > 0" />
        </div>
        <h2 v-show="historylist.length <= 0" style="text-align: center;">暂无历史打分记录</h2>
      </div>
    </div>
  </div>
  <a-modal v-model:visible="tipsShow" title="提示" :confirmLoading="loading">
    <div>
      <div style="color:#6B778C">该承运商此次评分为：{{ scoredata.total }}分，等级：{{ scoredata.level }}星，已不符合公司考核</div>
      <span style="color:#f00">是否将其转入淘汰期？</span>
    </div>

    <template #footer>
      <div>
        <a-space>
          <a-button @click="tipsShow = false, loadData()">继续沿用</a-button>
          <a-button type="primary" @click="eliminate">转入淘汰期</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { gradeCarrier, gradeSave, getHistoryData, carrierRateData, carrierPeriod } from '@/api/carrier/grade' // 1764536048603201537
import { message } from 'ant-design-vue';

export default {
  props: {
    carrierId: String,
    periodType: Object
  },
  setup (props, context) {
    const state = reactive({
      loading: false,
      editSHow: false,
      addShow: false,
      loadShow: false,
      tipsShow: false,
      rateId: '',
      scoredata: {
        level: 0,
        prescription: 0, // 时效-分数
        loss: 0, // 质损-分数
        operation: 0, // 操作-分数
        service: 0, // 服务-分数
        total: 0 // 合计
      },
      graderData: {
        carrierId: '',
        grader: null,
        updateTime: '',
        prescription: 0, // 时效-分数
        loss: 0, // 质损-分数
        operation: 0, // 操作-分数
        service: 0, // 服务-分数
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      mirrorData: [],
      mirrorGraderData: {},
      tableArray: [],
      mirrorTableArray: [],
      historylist: [],
      contentShow: true,
      gradeDetailShow: false,
    })
    onMounted(() => {
      state.mirrorGraderData = JSON.stringify(state.graderData)
      // for (let i = 0; i < 12; i++) {
      //   state.mirrorTableArray.push({
      //     carrierId: 0,
      //     contentObj: {
      //       lossIndemnity: 0,
      //       massLoss: 0,
      //       massLossNum: 0,
      //       massLossRate: "",
      //       massLossTotalNum: 0,
      //       onTimeRate: "",
      //       timeoutNum: 0,
      //       totalNum: 0
      //     },
      //     updateTime: "",
      //     grader: "",
      //     id: 0,
      //     indexContent: "",
      //     level: 0,
      //     rateId: 0,
      //     rateType: {
      //       label: "",
      //       value: 0
      //     },
      //     remark: "",
      //     score: 0
      //   })
      // }
    })
    const loadData = () => {
      state.graderData.carrierId = props.carrierId
      state.contentShow = true
      state.pagination.current = 1
      state.loadShow = true
      state.historylist = []
      getHistoryData({
        carrierId: props.carrierId,
        current: state.pagination.current
      }).then(res => {
        if (res.code === 10000) {
          res.data.records.forEach(item => {
            state.historylist.push(item)
          })
          console.log('historylist', state.historylist);
          state.pagination.total = res.data.total
          if (res.data.total > 0) {
            state.editSHow = false
            state.addShow = false
            state.rateId = res.data.records[0].rateId
            getGradeDetail()
          } else {
            loadCarrierRade()
          }
        }
      }).finally(() => {
        state.loadShow = false
      })
    }
    const getGradeDetail = () => {
      gradeCarrier({
        // rateId: '1764536048603201537'
        rateId: state.rateId
      }).then(res => {
        if (res.code === 10000) {
          state.graderData = {
            carrierId: props.carrierId,
            grader: res.data.grader,
            updateTime: res.data.updateTime,
            prescription: res.data.prescription, // 时效-分数
            loss: res.data.loss, // 质损-分数
            operation: res.data.operation, // 操作-分数
            service: res.data.service // 服务-分数
          }
          state.mirrorData = res.data.carrierRateDTOList
          state.tableArray = res.data.carrierRateDTOList
          state.scoredata.prescription = Number(state.tableArray[0].score) + Number(state.tableArray[1].score) + Number(state.tableArray[2].score) + Number(state.tableArray[3].score)
          state.scoredata.loss = Number(state.tableArray[4].score) + Number(state.tableArray[5].score)
          state.scoredata.operation = Number(state.tableArray[6].score) + Number(state.tableArray[7].score) + Number(state.tableArray[8].score)
          state.scoredata.service = Number(state.tableArray[9].score) + Number(state.tableArray[10].score) + Number(state.tableArray[11].score) + Number(state.tableArray[12].score)
          state.scoredata.total = state.scoredata.prescription + state.scoredata.loss + state.scoredata.operation + state.scoredata.service
        }
      })
    }
    const loadCarrierRade = () => {
      carrierRateData({
        carrierId: props.carrierId
      }).then(res => {
        if (res.code === 10000) {
          state.graderData = {
            carrierId: props.carrierId,
            grader: res.data.grader,
            updateTime: res.data.updateTime,
            prescription: res.data.prescription, // 时效-分数
            loss: res.data.loss, // 质损-分数
            operation: res.data.operation, // 操作-分数
            service: res.data.service // 服务-分数
          }
          state.tableArray = res.data.carrierRateDTOList
          state.tableArray.forEach(item => {
            item.score = Math.trunc(Math.trunc(item.contentObj.onTimeRate) / 10)
          })
          state.scoredata.prescription = state.tableArray[0].score + state.tableArray[1].score + state.tableArray[2].score + state.tableArray[3].score
          state.addShow = true
          state.contentShow = false
        }
      })
    }
    const loadHistoryData = () => {
      state.loadShow = true
      getHistoryData({
        carrierId: props.carrierId,
        current: state.pagination.current
      }).then(res => {
        if (res.code === 10000) {
          res.data.records.forEach(item => {
            state.historylist.push(item)
          })
          state.pagination.total = res.data.total
        }
      }).finally(() => { state.loadShow = false })
    }
    const submitApi = () => {
      state.loading = true
      carrierPeriod({
        carrierId: props.carrierId,
        level: state.scoredata.level,
        periodType: 4
      }).then(res => {
        if (res.code === 10000) {
          state.tipsShow = false
          state.contentShow = true
          state.editSHow = false
          state.addShow = false
          context.emit('update', true)
          loadData()
          message.success('分数提交成功')
        }
      }).finally(() => { state.loading = false })
    }
    const submitGrade = () => {
      if (state.scoredata.total <= 100) {
        if (state.scoredata.prescription <= state.graderData.prescription) {
          if (state.scoredata.loss <= state.graderData.loss) {
            if (state.scoredata.operation <= state.graderData.operation) {
              if (state.scoredata.service <= state.graderData.service) {
                state.loading = true
                gradeSave({
                  ...state.graderData,
                  carrierRateDTOList: state.tableArray
                }).then(res => {
                  if (res.code === 10000) {
                    state.scoredata.level = res.data.level
                    state.scoredata.score = res.data.score
                    if (state.scoredata.level > 2) {
                      loadData()
                    } else {
                      state.tipsShow = true
                    }
                  }
                }).finally(() => { state.loading = false })
              } else message.error('服务类别总分错误，请检查')
            } else message.error('操作类别总分错误，请检查')
          } else message.error('质损类别总分错误，请检查')
        } else message.error('时效类别总分错误，请检查')
      } else message.error('分数合计错误，不可高于100分')
    }
    const cooperate = () => {
      submitApi()
    }
    const eliminate = () => {
      state.loading = true
      carrierPeriod({
        carrierId: props.carrierId,
        level: state.scoredata.level,
        periodType: 5
      }).then(res => {
        if (res.code === 10000) {
          state.tipsShow = false
          state.contentShow = true
          state.editSHow = false
          state.addShow = false
          context.emit('update', true)
          loadData()
          message.success('分数提交成功')
        }
      }).finally(() => { state.loading = false })
    }
    const openDetail = (item) => {
      // state.gradeDetailShow = true
      state.rateId = item.rateId
      state.contentShow = true
      state.editSHow = false
      state.addShow = false
      getGradeDetail()
    }
    const addGrade = () => {
      // state.tableArray.forEach(item => {
      //   item.carrierId = props.carrierId
      //   item.level = 0
      //   item.remark = ''
      //   item.score = ''
      //   delete item.id
      //   delete item.rateId
      // })
      for (const key in state.scoredata) {
        state.scoredata[key] = 0
      }
      loadCarrierRade()
      state.graderData.grader = null
      state.graderData.updateTime = null
      state.graderData.carrierId = props.carrierId
      state.contentShow = false
      state.addShow = true
    }
    const editBtn = () => {
      state.editSHow = true
      state.contentShow = false
    }
    const scoreChange = index => {
      if (index <= 3) {
        state.scoredata.prescription = Number(state.tableArray[0].score) + Number(state.tableArray[1].score) + Number(state.tableArray[2].score) + Number(state.tableArray[3].score)
      }
      if (index > 3 && index <= 5) {
        state.scoredata.loss = Number(state.tableArray[4].score) + Number(state.tableArray[5].score)
      }
      if (index > 5 && index <= 8) {
        state.scoredata.operation = Number(state.tableArray[6].score) + Number(state.tableArray[7].score) + Number(state.tableArray[8].score)
      }
      if (index > 8) {
        state.scoredata.service = Number(state.tableArray[9].score) + Number(state.tableArray[10].score) + Number(state.tableArray[11].score) + Number(state.tableArray[12].score)
      }
      state.scoredata.total = state.scoredata.prescription + state.scoredata.loss + state.scoredata.operation + state.scoredata.service
    }
    const loadMore = () => {
      if (state.historylist.length < state.pagination.total) {
        state.pagination.current++
        loadHistoryData()
      } else message.info('没有更多数据了')
    }
    const closeShow = () => {
      state.tipsShow = false
      state.editSHow = false
    }

    return {
      ...toRefs(state),
      loadData,
      addGrade,
      editBtn,
      loadMore,
      eliminate,
      closeShow,
      scoreChange,
      submitGrade,
      cooperate,
      loadCarrierRade,
      openDetail,
      loadHistoryData,
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
