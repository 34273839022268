<template>
  <div>

    <div style="display:flex;justify-content:flex-end;margin:10px 0" v-if="buttonType">
      <a-button v-show="editDisabled === false" @click="editConfirm" :loading="loading"
        class="searchLoading-button">保存</a-button>
      <a-button @click="editDisabled = !editDisabled" style="margin-left:15px">{{ editDisabled ? '编辑' : '取消' }}</a-button>
    </div>
    <a-form layout="vertical" ref="formRef" :model="editData" :rules="rules">
      <a-row :gutter="[24, 12]">
        <a-col :span="12">
          <a-form-item label="录入人">
            <a-input v-model:value="editData.creator" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="创建时间">
            <a-input v-model:value="editData.createTime" disabled />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <div style="display: flex;">
            <a-form-item style="flex:1" label="承运商名称">
              <div v-show="editData.carrierNatureType?.value === 1">
                <a-input :disabled="editDisabled" v-model:value="editData.name" />
              </div>
              <div v-show="editData.carrierNatureType?.value === 2">
                <a-select v-model:value="editData.name" :disabled="editDisabled" placeholder="请输入至少三位承运商名称"
                  :filter-option="false" :show-arrow="false" @search="entpSearch" @select="entpSelect" show-search>
                  <a-select-option v-for="item in enterprises" :key="item.creditCode" :value="item.name">
                    <span>{{ item.name }}</span>
                    <span style="float:right;">{{ item.operName }}</span>
                  </a-select-option>
                </a-select>
              </div>
            </a-form-item>
            <a-form-item style="flex:1;margin-left:10px" v-show="editData.carrierType?.value === 1" label="承运商别名">
              <a-input :disabled="editDisabled" v-model:value="editData.alias" />
            </a-form-item>
          </div>
        </a-col>
        <a-col :span="12">
          <a-form-item label="结算方式">
            <a-select allowClear v-model:value="editData.settlementMode.label" :options="CustomerSettlementEnum"
              @change="settlementChange" placeholder="请选择结算方式" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <div style="display: flex;">
            <a-form-item style="flex:1;" label="负责人姓名">
              <a-input v-model:value="editData.charge" :disabled="editDisabled" />
            </a-form-item>
            <a-form-item style="flex:1;margin-left:10px" label="负责人电话" name="chargeMobile">
              <a-input v-model:value="editData.chargeMobile" :disabled="editDisabled" />
            </a-form-item>
          </div>
        </a-col>
        <a-col :span="12">
          <div style="display: flex">
            <a-form-item label="是否为固化承运商" style="flex:1">
              <a-select :disabled="editDisabled" v-model:value="editData.isSettled.value"
                :options="$store.state.enumAll.BooleanFlagEnum" placeholder="请选择" />
            </a-form-item>
            <a-form-item label="是否自有大板" style="flex:1;margin-left:10px">
              <a-select v-model:value="editData.isBigCar.value" :disabled="editDisabled"
                :options="$store.state.enumAll.BooleanFlagEnum" />
            </a-form-item>
          </div>
        </a-col>
        <a-col :span="12" style="display:flex;justify-content:space-between;">
          <div>
            <a-form-item label="营业执照照片" @click="flag = 1">
              <div v-show="editDisabled"> <a-image :width="100" @click="onUrl(1)"
                  :src="editData.licenseFileUrl?.previewUrl || require('@/assets/images/nullImg.jpg')" /></div>
              <a-upload v-show="!editDisabled" v-model:file-list="licenseFile" list-type="picture-card"
                class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
                <img style="width:150px;height:150px"
                  :src="editData.licenseFileUrl?.previewUrl || require('@/assets/images/nullImg.jpg')" />
                <div v-if="!editData.licenseFileUrl">
                  <loading-outlined v-if="licenseLoading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-item>
          </div>
        </a-col>
        <a-col :span="12" style="display: flex;justify-content: space-around;">
          <a-form-item label="身份证正面" @click="flag = 2">
            <a-image v-if="editDisabled && editData.fileUrl != null" :width="100" @click="onUrl(2)"
              :src="editData.fileUrl.previewUrl" />
            <a-upload v-show="!editDisabled" v-model:file-list="idCardseFile" list-type="picture-card"
              class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
              <img v-if="editData.fileUrl" style="width:150px;height:150px" :src="editData.fileUrl.previewUrl" />
              <div v-else>
                <loading-outlined v-if="idCardseLoading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item label="身份证反面" @click="flag = 3">
            <a-image v-if="editDisabled && editData.idCardEndUrl != null" :width="100" @click="onUrl(2)"
              :src="editData.idCardEndUrl.previewUrl" />
            <a-upload v-show="!editDisabled" v-model:file-list="idCardEndFile" list-type="picture-card"
              class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
              <img v-if="editData.idCardEndUrl" style="width:150px;height:150px"
                :src="editData.idCardEndUrl.previewUrl" />
              <div v-else>
                <loading-outlined v-if="idCardEndLoading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="editData.carrierBusinessType">
          <a-form-item label="业务类型">
            <a-select v-model:value="editData.carrierBusinessType.value" :options="$store.state.enumAll.businessType"
              @change="customerTypeChange" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="editData.carrierCapacityType">
          <a-form-item label="运力属性">
            <a-select v-model:value="editData.carrierCapacityType.value" :options="$store.state.enumAll.Attribute"
              @change="attributeChange" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="承运方对接人">
            <a-input v-model:value="editData.dockingPerson" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="承运方对接人电话" name="dockingMobile">
            <a-input v-model:value="editData.dockingMobile" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="地址" name="addressArr">
            <a-cascader v-model:value="editData.addressArr" :options="$store.state.app.cityDict" :show-search="{ filter }"
              :changeOnSelect="true" @change="addressChange" placeholder="请选择省市区" :disabled="editDisabled" />
            <a-input v-model:value="editData.address.details" placeholder="请输入具体位置" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
        <!-- <a-col :span="12">
          <a-form-item label="客户标签">
            <a-select v-model:value="editData.tagId" :options="$store.state.enumAll.CarrierEnum" @change="tagChange"
              :disabled="editDisabled" />
          </a-form-item>
        </a-col> -->
        <a-col :span="12">
          <a-form-item label="备注">
            <a-input v-model:value="editData.remark" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="承运商类型">
            <a-select v-model:value="editData.carrierType.value" :options="$store.state.enumAll.carrierType"
              @change="carrierChange" :disabled="editDisabled" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- <div>
      <a-row :gutter="[24, 12]">
        <a-col :span="4">
          承运商名称：
        </a-col>
        <a-col :span="8">
          {{ editData.name }}
        </a-col>
        <a-col :span="4">
          结算方式：
        </a-col>
        <a-col :span="8">
          {{ editData.settlementMode?.label }}
        </a-col>
        <a-col :span="4">
          负责人姓名：
        </a-col>
        <a-col :span="8">
          {{ editData.charge }}
        </a-col>
        <a-col :span="4">
          负责人电话：
        </a-col>
        <a-col :span="8">
          {{ editData.chargeMobile }}
        </a-col>
        <a-col :span="4">
          营业执照照片
        </a-col>
        <a-col :span="8">
          <img v-if="editData.licenseFileUrl" style="width:20px;height:20px" :src="editData.licenseFileUrl?.previewUrl" />
        </a-col>
        <a-col :span="4">
          法人身份证照片
        </a-col>
        <a-col :span="8">
          <img v-if="editData.fileUrl" style="width:20px;height:20px" :src="editData.fileUrl.previewUrl" />
        </a-col>
        <a-col :span="4">
          业务类型：
        </a-col>
        <a-col :span="8">
          {{ editData.carrierBusinessType?.label }}
        </a-col>
        <a-col :span="4">
          运力属性：
        </a-col>
        <a-col :span="8">
          {{ editData.carrierCapacityType?.label }}
        </a-col>
        <a-col :span="4">
          提交人：
        </a-col>
        <a-col :span="8">
          {{ editData.creator }}
        </a-col>
        <a-col :span="4">
          提交时间：
        </a-col>
        <a-col :span="8">
          {{ editData.createTime }}
        </a-col>
      </a-row>
    </div> -->
  </div>
</template>

<script>
import { mobileValidate } from '@/utils/validate'
import { onMounted, reactive, toRefs, ref } from 'vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { detailData, dataEdit } from '@/api/carrier/detailData'
import { useStore } from 'vuex'
import { fileUploadPublic as fileUpload } from '@/api/global'
import { message } from 'ant-design-vue'
import { enterprise } from '@/api/system/setting'
export default {
  components: {
    LoadingOutlined,
    PlusOutlined
  },
  props: { carrierId: String, buttonType: Boolean },
  setup (props) {
    const formRef = ref()
    const store = useStore()
    const state = reactive({
      enterprises: [],
      flag: 0,
      carrierId: props.carrierId,
      buttonType: props.buttonType,
      licenseImg: '',
      idCardseImg: '',
      loading: false,
      idCardEndLoading: false,
      licenseLoading: false,
      idCardseLoading: false,
      editDisabled: true,

      licenseFile: [],
      idCardseFile: [],
      idCardEndFile: [],
      CustomerSettlementEnum: [],
      editData: {
        import: '',
        addressArr: [],
        fileUrl: {},
        idCardEndUrl: {},
        licenseFileUrl: {},
        createTime: '',
        charge: '',
        chargeMobile: '',
        isSettled: { value: 0 },
        carrierBusinessType: { value: null },
        settlementMode: { value: null },
        carrierCapacityType: { value: null },
        isBigCar: { value: null },
        dockingPerson: '',
        dockingMobile: '',
        address: { details: '' },
        customerLabel: '',
        remark: '',
        carrierType: { value: 0 }
      },
      rules: {
        chargeMobile: [{ required: true, message: '请填写电话', trigger: 'blur' }, { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }],
        addressArr: { required: true, message: '请填写常用地址', trigger: 'blur', type: 'array' },
        dockingMobile: [{ required: true, message: '请填写电话', trigger: 'blur' }, { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }]
      }
    })
    const loadData = () => {
      state.editDisabled = true
      detailData(state.carrierId)
        .then(res => {
          if (res.code === 10000) {
            if (res.data.carrierCapacityType === null) res.data.carrierCapacityType = { value: '' }
            if (!res.data.isSettled) res.data.isSettled = { value: '' }
            state.editData = res.data
            state.editData.addressArr = [res.data.address.province, res.data.address.city, res.data.address.area]
          }
        }).catch(err => {
          console.log(err)
        })
    }
    onMounted(() => {
      // loadData()
      setTimeout(() => {
        state.CustomerSettlementEnum = store.state.enumAll.CustomerSettlementEnum
        if (state.CustomerSettlementEnum[0].value === '' || state.CustomerSettlementEnum[0].value === 0) state.CustomerSettlementEnum.splice(0, 1)
      }, 200)
    })

    // 图片上传
    const handleChange = info => {
      switch (state.flag) {
        case 1:
          state.licenseLoading = true
          const formData = new FormData()
          formData.append('file', state.licenseFile[0])
          fileUpload(formData).then(res => {
            if (res.code === 10000) {
              console.log('editData', state.editData);

              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                if (state.editData.licenseFileUrl === null) {
                  state.editData.licenseFileUrl = {}
                }
                state.editData.licenseFileUrl.previewUrl = res.data.url
                state.editData.licenseFileId = res.data.id
                state.licenseLoading = false
                state.licenseFile = []
              }
            }
          })
          break
        case 2:
          state.idCardseLoading = true
          const fileImg = new FormData()
          fileImg.append('file', state.idCardseFile[0])
          console.log(state.idCardseFile[0], fileImg);
          fileUpload(fileImg).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                if (state.editData.fileUrl === null) {
                  state.editData.fileUrl = {}
                }
                state.editData.fileUrl.previewUrl = res.data.url
                state.editData.fileId = res.data.id
                state.idCardseLoading = false
                state.idCardseFile = []
              }
            }
          })
          break
        case 3:
          state.idCardEndLoading = true
          const idCardform = new FormData()
          idCardform.append('file', state.idCardEndFile[0])
          console.log(state.idCardEndFile[0], idCardform);
          fileUpload(idCardform).then(res => {
            if (res.code === 10000) {
              if (res.data.url !== '' || res.data.url !== null || res.data.url !== undefined) {
                if (state.editData.idCardEndUrl === null) {
                  state.editData.idCardEndUrl = {}
                }
                state.editData.idCardEndUrl.previewUrl = res.data.url
                state.editData.idCardEnd = res.data.id
                state.idCardEndLoading = false
                state.idCardEndFile = []
              }
            }
          })
          break
      }
    }

    const beforeUpload = file => {
      switch (state.flag) {
        case 1:
          state.licenseFile.push(file)
          break
        case 2:
          state.idCardseFile.push(file)
          break
        case 3:
          state.idCardEndFile.push(file)
          break
      }
      return false
    }
    const settlementChange = (e, v) => {
      state.editData.settlementMode = v
    }
    const tagChange = (e, v) => {
      state.editData.tagName = v.label
    }
    const addressChange = (value, selectedOptions) => {
      state.editData.address.province = value[0]
      state.editData.address.city = value[1]
      state.editData.address.area = value[2]
    }
    const carrierChange = (e, v) => {
      state.editData.carrierType = v
    }
    const customerTypeChange = (e, v) => {
      state.carrierBusinessType = v
    }
    const attributeChange = (e, v) => {
      state.editData.carrierCapacityType = v
    }
    const editConfirm = () => {
      formRef.value.validate().then(() => {
        state.loading = true
        state.editData.fileUrl = null
        state.editData.licenseFileUrl = null
        dataEdit({ ...state.editData, carrierId: state.carrierId })
          .then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.editDisabled = true
              loadData()
            }
          }).catch(err => { console.log(err) }).finally(() => { state.loading = false })
      })
    }
    const onUrl = (index) => {
      switch (index) {
        case 1:
          if (state.editData.licenseFileUrl?.url) state.editData.licenseFileUrl.previewUrl = state.editData.licenseFileUrl.url
          break;
        case 2:
          if (state.editData.fileUrl?.url) state.editData.fileUrl.previewUrl = state.editData.fileUrl.url
          if (state.editData.idCardEndUrl?.url) state.editData.idCardEndUrl.previewUrl = state.editData.idCardEndUrl.url
          break;
      }
    }
    const entpSelect = async (val, option) => {
      state.editData.certificateNumber = option.key
      state.enterprises = []
    }
    const entpSearch = async val => {
      if (val.length > 3) {
        const filterVal = val.replace(new RegExp('[a-z]', 'ig'), '').replace(new RegExp('[0-9]', 'g'), '')
        if (filterVal.length < 4) {
          return
        }
        state.timeOut = setTimeout(() => {
          enterprise({ name: val }).then((res) => {
            if (res.code === 10000) {
              state.enterprises = res.data
            } else {
              state.enterprises = []
            }
          })
        }, 500)
      } else {
        state.enterprises = []
      }
    }
    return {
      ...toRefs(state),
      editConfirm,
      loadData,
      settlementChange,
      addressChange,
      tagChange,
      carrierChange,
      handleChange,
      beforeUpload,
      attributeChange,
      customerTypeChange,
      onUrl,
      formRef,
      entpSearch,
      entpSelect
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>
