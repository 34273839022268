import { getRequest, postBodyRequest} from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/carrier/emp/getEmployeeList', params)
}


export function add (params) {
  return postBodyRequest('/carrier/emp/addEmployee', params)
}

export function deleteId (params) {
  return getRequest('/carrier/emp/deleteEmployee', params)
}
