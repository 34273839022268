import { postBodyRequest, getRequest } from '@/utils/axios'

export function page (params) {
  return postBodyRequest('/carrier/app/acct/getAccountList', params)
}

export function add (params) {
  return postBodyRequest('/carrier/app/acct/addAccount', params)
}

export function updatePwd (params) {
  return getRequest('/carrier/app/acct/updatePwd', params)
}
export function deleteId (params) {
  return getRequest('/carrier/app/acct/delete', params)
}
export function resetPassword (params) {
  return getRequest('/carrier/app/acct/resetPassword', params)
}

// 停用启用
export function acctIsEnable (id) {
  return getRequest(`/carrier/app/acct/is-enable?id=${id}`, )
}