<template>
  <div>
    <h2>周期流转日志</h2>
    <div class="content-div" v-for="(item, index) in listData" :key="index">
      <div class="title-content">
        <div style="color:#333;font-weight: bold;">{{ item.periodType?.label }}</div>
        <div class="color2">{{ item.circulationName }}</div>
        <a-divider v-show="index+1 < listData.length" type="vertical"
          style="height: 60px; background-color: #eee" />
      </div>
      <div class="context-div">
        <div>{{ item.content }}</div>
        <div class="color2">{{ item.time }}</div>
      </div>
    </div>
    <div style="height:15px"></div>
    <a-pagination v-model:current="pagination.current" v-model:pageSize="pagination.pageSize" :total="pagination.total" show-less-items @change="pageChange"/>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { circulationPage } from '@/api/carrier/carrierList'

export default {
  props: {
    carrierId: String
  },
  setup (props) {
    const state = reactive({
      listData: [],
      loading: false,
      pagination: {
        current: 1,
        pageSize: 6,
        total: 0
      },
    })
    const loadData = () => {
      state.loading = true
      circulationPage({
        carrierId: props.carrierId,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          console.log(state.pagination.total);
        }
      }).finally(() => { state.loading = false })
    }
    const pageChange = (page,size) => {
      state.pagination.current = page
      loadData()
    }
    return {
      ...toRefs(state),
      loadData,
      pageChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
