<template>
  <div>
    <div v-if="listData.length === 0" style="display:flex;justify-content:flex-end;margin:10px 0">
      <a-button @click="addShow = true">+新增账号</a-button>
    </div>
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #isEnable="{ record }">
        <a-popconfirm ok-text="确认" cancel-text="取消" @confirm="confirmStop(record)">
          <template #title>
            <div style="padding-right:15px">
              <span v-if="record.isEnable.value===1">确认停用账号吗</span>
              <span v-if="record.isEnable.value===0">确认打开账号吗</span>
            </div>
          </template>
          <a-switch :checked="record.isEnable.value" :checkedValue="1" :unCheckedValue="0" />
        </a-popconfirm>
      </template>
      <template #operation="{ record }">
        <a-space>
          <a-popconfirm ok-text="确认" cancel-text="取消" @confirm="confirmEdit(record)">
            <template #title>
              <div style="padding-right:15px">
                <span>确认重置密码</span>
              </div>
            </template>
            <a>重置密码</a>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
    <a-modal :width="750" title="新增账号" @ok="onAdd" v-model:visible="addShow">
      <a-form layout="vertical" ref="_addRef" :model="addForm" :rules="rules">
        <a-form-item name="accName">
          <template #label>
            账户名称(密码默认为手机号后6位)
          </template>
          <a-input v-model:value="addForm.accName" :onKeyUp="addForm.accName = addForm.accName.replace(/[\W]/g, '')" />
        </a-form-item>
        <!-- <a-form-item label="密码" name="password">
          <a-input-password autosize="true" v-model:value="addForm.password" />
        </a-form-item> -->
        <a-form-item label="关联手机电话" name="phone">
          <a-input v-model:value="addForm.phone" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { page, add, deleteId, resetPassword, acctIsEnable } from '@/api/carrier/accountList'
import { message } from 'ant-design-vue'
import { mobileValidate } from '@/utils/validate'
// import OptionalMap from '@/views/components/optionalMap'
export default {
  props: {
    carrierId: String
  },
  setup (props, context) {
    const _addRef = ref(null)
    const state = reactive({
      carrier: props.carrierId,
      listData: [],
      pagination: {},
      addForm: {
        accName: '',
        password: '',
        phone: ''
      },
      mirrorAddForm: {},
      editForm: {},
      loading: false,
      addShow: false,
      editShow: false,
      columns: [{
        title: '用户名',
        dataIndex: 'accName',
        align: 'center'
      },
      {
        title: '关联手机号',
        dataIndex: 'phone',
        align: 'center'
      },
      {
        title: '开通时间',
        dataIndex: 'createTime',
        align: 'center'
      },
      {
        title: '开通人',
        dataIndex: 'operator',
        align: 'center'
      },
      {
        title: '当前状态',
        dataIndex: 'isEnable.label',
        align: 'center'
      },
      {
        title: '停用/启用',
        dataIndex: 'isEnable',
        slots: { customRender: 'isEnable' },
        align: 'center'
      },
      {
        title: '操作',
        slots: {
          customRender: 'operation'
        }
      }
      ],
      rules: {
        accName: { required: true, message: '请填写账号', trigger: 'blur' },
        password: { required: true, message: '请输入密码', trigger: 'blur' },
        phone: [{ required: true, message: '请填写电话号码', trigger: 'blur' }, { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }]
      }
    })
    onMounted(() => {
      state.mirrorAddForm = JSON.stringify(state.addForm)
    })
    // 启用停用
    const confirmStop = (e) => {
      acctIsEnable(e.userId).then(res => {
        if (res.code !== 10000) return
        message.success(res.msg)
        if (e.isEnable.value) {
          e.isEnable.value = 0
        } else {
          e.isEnable.value = 1
        }
      })
    }

    // 表格数据加载
    const loadData = () => {
      state.loading = true
      page(
        {
          carrierId: props.carrierId,
          current: state.pagination.current,
          size: state.pagination.pageSize
        }).then(res => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.pagination.current = res.data.current
            state.pagination.pageSize = res.data.size
            state.pagination.total = res.data.total
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          state.loading = false
        })
    }
    // 删除
    const deleteData = (e) => {
      deleteId({ accountId: e.id }).then(res => {
        message.info(res.msg)
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        loadData()
      })
    }
    // 表格分页
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 新增
    const onAdd = () => {
      _addRef.value.validate()
        .then(() => {
          add({ carrierId: props.carrierId, ...state.addForm }).then(res => {
            if (res.code === 10000) {
              state.addShow = false
              message.success(res.msg)
              state.addForm = JSON.parse(state.mirrorAddForm)
              loadData()
            }
          }).catch(err => {
            console.log(err)
          })
        })
    }
    // 重置密码
    const onEditPwdShow = (e) => {
      resetPassword({ accountId: e.userId }).then(res => {
        if (res.code === 10000) {
          state.addShow = false
          message.success('重置成功')
          state.addForm = {}
          loadData()
        }
      }).catch(err => {
        console.log(err)
      })
    }
    // 重置密码
    const confirmEdit = record => {
      onEditPwdShow(record);
    }

    return {
      ...toRefs(state),
      handleTableChange,
      loadData,
      deleteData,
      onAdd,
      onEditPwdShow,
      confirmEdit,
      _addRef,
      confirmStop
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>
