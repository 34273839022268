import { exportRequest, postBodyRequest} from '@/utils/axios'

export function getPage (params) {
  return postBodyRequest('/trans/transport/carrier/orderVehiclePage', params)
}

export function deriveData (params) {
  return exportRequest('/trans/transport/carrier/orderVehiclePage/export', params)
}

export function getTotalFreight (params) {
  return postBodyRequest('/trans/transport/carrier/totalFreight', params)
}