<template>
  <div>
    <!-- 头部 -->
    <div class="header">
      <div class="title-name">
        <div style="font-size:20px;display:flex;align-items:center">
          <span style="color:#6B778C">[{{ detailData.carrierType?.label }}] </span>&ensp;{{ detailData.carrierName }}
          <span v-show="fromData.alias">{{ `[${fromData.alias || ''}]` }}</span>
          <a-rate v-if="fromData.carrierLevel" :value="fromData.carrierLevel.value" :disabled="true" />
        </div>
        <div>
          <a-tag :color="colors[fromData.periodType?.value]">{{ fromData.periodType?.label }}</a-tag>
          <a-tag v-show="fromData.isBlock?.value === 1">已拉黑</a-tag>
        </div>
      </div>
      <div>
        <a-space>
          <!-- <a-button @click="gradeShow = true">
            <template #icon>
              <StarOutlined />
            </template>
承运商评级
</a-button> -->
          <!-- <a-button @click="labelShow = true">

            <template #icon>
              <FormOutlined />
            </template>修改标签
          </a-button> -->
          <a-button v-show="fromData.isBlock?.value === 1" @click="openShow">

            <template #icon>
              <SyncOutlined />
            </template>
            <span>取消拉黑</span>
          </a-button>
          <a-button v-show="fromData.periodType?.value === 5 || fromData.periodType?.value === 1" @click="openShow">

            <template #icon>
            </template>
            <span>重新启用</span>
          </a-button>
          <a-button v-show="fromData.isBlock?.value === 0 && fromData.periodType?.value === 4"
            @click="filterOutShow = true">

            <template #icon>
              <StopOutlined />
            </template>
            <span>拉黑承运商</span>
          </a-button>
          <!-- <a-popconfirm :title="blackTitle" @confirm="blacklist" ok-text="确认" cancel-text="取消">

          </a-popconfirm> -->

        </a-space>
      </div>
    </div>
    <!-- 横幅数据 -->
    <div class="banner-data">
      <div class="data-box">
        <p class="data-title">负责人</p>
        <p>
          {{ fromData.charge }}
          <span>{{ fromData.chargeMobile }}</span>
        </p>
      </div>
      <div class="data-box">
        <p class="data-title">累计台量</p>
        <p>{{ consumeData.totalNum }}</p>
      </div>
      <div class="data-box">
        <p class="data-title">累计运费(元)</p>
        <p>
          {{ consumeData.totalTransFee }}
          <span style="color:#f00;margin-left:10px">未结算：{{ consumeData.totalUnsettledAmt }}</span>
        </p>
      </div>
      <div class="data-box">
        <p class="data-title">最近承运</p>
        <p>
          {{ consumeData.latestLineName }}
          <span style="margin: 0 10px">{{ consumeData.latestTransFee * 1 > 0 ? consumeData.latestTransFee : ''}}</span>
          <span>{{ consumeData.lastCreateTime }}</span>
        </p>
      </div>
      <div class="data-box">
        <p class="data-title">创建时间</p>
        <p>{{ carrierData.createTime }}</p>
      </div>
    </div>
    <!-- 表格tabs -->
    <div class="table-data">
      <a-tabs v-model:activeKey="activeKey" size="large" :tabBarGutter="40" style="flex:1" @change="tabsChange">
        <a-tab-pane :key="1" tab="车辆列表">
          <VehicleList :carrierId="carrierId" ref="_vehicleList" />
        </a-tab-pane>
        <a-tab-pane :key="2" tab="司机列表">
          <DriverList :carrierId="carrierId" ref="_driverList" />
        </a-tab-pane>
        <a-tab-pane :key="3" tab="承运线路">
          <ContractRoute :carrierId="carrierId" ref="_contractRoute" />
        </a-tab-pane>
       
        <a-tab-pane :key="5" tab="详细资料">
          <DetailData :carrierId="carrierId" ref="_detailData" :buttonType="buttonType" />
        </a-tab-pane>
        <a-tab-pane :key="6" tab="地址库">
          <AddressList :carrierId="carrierId" ref="_addressList" />
        </a-tab-pane>
        <!-- <a-tab-pane :key="7" tab="质损">
          <DamageList :carrierId="carrierId" ref="_damageList" v-show="activeKey===7" />
        </a-tab-pane> -->
       
        <a-tab-pane :key="10" tab="员工管理" v-if="detailData.carrierType?.value === 1">
          <StaffList :carrierId="carrierId" ref="_staffList" />
        </a-tab-pane>
        <a-tab-pane :key="11" tab="承运任务">
          <ContractTask :carrierId="carrierId" ref="_contractTask" />
        </a-tab-pane>
        <!-- <a-tab-pane :key="13" tab="评分看板" v-if="fromData.periodType?.value !== 1&&fromData.periodType?.value !== 2"> -->
        <a-tab-pane :key="13" tab="评分看板" v-if="fromData.periodType?.value !== 1">
          <GradeData :carrierId="carrierId" :periodType="fromData.periodType" ref="_gradeData" @update="updateGrade" />
        </a-tab-pane>
        <a-tab-pane :key="14" tab="质损列表">
          <QualityList :carrierId="carrierId" ref="_qualityList" />
        </a-tab-pane>
        <a-tab-pane :key="15" tab="流转日志">
          <Journal :carrierId="carrierId" ref="_journal" />
        </a-tab-pane>
        <a-tab-pane :key="9" tab="账号管理" v-if="detailData.isSettled?.value === 1">
          <AccountList :carrierId="carrierId" ref="_accountList" />
        </a-tab-pane>
        <a-tab-pane :key="12" tab="通联账号">
          <BindMembers :carrierId="carrierId" :carrierNatureType="detailData.carrierNatureType.value"
            v-if="activeKey === 12 && detailData.carrierNatureType" ref="_bindMembers" />
        </a-tab-pane>
        <a-tab-pane :key="4" tab="财务信息">
          <FinanceData :carrierId="carrierId" ref="_financeData" />
        </a-tab-pane>
      </a-tabs>
      <!-- <div class="abstract-data">
        <h2>客户摘要</h2>
        <p>承运总次数：188次</p>
        <p>托运总金额：999,99元</p>
        <p>待结算金额：999,9元</p>
        <p>大板总台数：12辆</p>
      </div> -->
    </div>
  </div>
  <!-- 承运商评级 -->
  <a-modal v-model:visible="gradeShow" title="承运商评级" @ok="grade">
    <a-rate v-model:value="setGrade" />
  </a-modal>
  <!-- 拉黑 -->
  <a-modal v-model:visible="filterOutShow" width="40%" title="拉黑承运商" @ok="conformFilterOut" :confirmLoading="loading">
    <div>
      <a-form-item>

        <template #label>
          <div>
            <span style="color: #f00;">*</span>选择原因
          </div>
        </template>
        <a-select v-model:value="blockReasonType">
          <a-select-option v-for="item in blockReasonList" :key="item.value" :value="item.value">{{ item.label
            }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="&nbsp;&emsp;&emsp;备注">
        <a-textarea v-model:value="blockRemark" placeholder="填写备注" :rows="4" showCount />
      </a-form-item>
    </div>
  </a-modal>
  <!-- 激活/启用 -->
  <a-modal v-model:visible="againShow" width="40%" :title="titleContent" @ok="confirmActivate"
    :confirmLoading="loading">
    <a-textarea v-model:value="enableRemark" placeholder="请输入原因，不少于50字" :rows="4" showCount />
  </a-modal>
  <!-- 修改标签 -->
  <a-modal v-model:visible="labelShow" title="修改标签" @ok="editLabel">
    <a-select style="width: 100%" v-model:value="fromData.tagId" :options="$store.state.enumAll.CarrierEnum"
      @change="labelChange" />
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import { StarOutlined, FormOutlined, StopOutlined, SyncOutlined } from '@ant-design/icons-vue'
import { detailData, dominantLine, SelectionGrade, black, filterOutAPI, filterOutReason, carrierUse, carrierActivate, consumptionData } from '@/api/carrier/carrierList'

import VehicleList from './vehicleList'
import DriverList from './driverList'
import ContractRoute from './contractRoute'
import FinanceData from './financeData'
import DetailData from './detailData'
import AddressList from './addressList'
import DamageList from './damageList'
import AccountList from './accountList'
import StaffList from './staffList'
import ContractTask from './contractTask'
import BindMembers from './bindMembers'
import GradeData from './gradeData'
import QualityList from './qualityList'
import Journal from './journal'
import { message } from 'ant-design-vue'

export default {
  components: {
    StarOutlined,
    FormOutlined,
    StopOutlined,
    VehicleList,
    DriverList,
    ContractRoute,
    FinanceData,
    DetailData,
    AddressList,
    AccountList,
    StaffList,
    DamageList,
    BindMembers,
    SyncOutlined,
    ContractTask,
    GradeData,
    QualityList,
    Journal
  },
  props: { carrierId: String, detail: Object },
  setup (props) {
    const _journal = ref()
    const _qualityList = ref()
    const _gradeData = ref()
    const _vehicleList = ref()
    const _driverList = ref()
    const _contractRoute = ref()
    const _financeData = ref()
    const _detailData = ref()
    const _addressList = ref()
    const _damageList = ref()
    const _accountList = ref()
    const _staffList = ref()
    const _contractTask = ref()
    const _bindMembers = ref()
    const state = reactive({
      fromData: {
        carrierLevel: {}
      },
      blackTitle: '拉黑承运商',
      status: true,
      loading: false,
      gradeShow: false,
      labelShow: false,
      blackShow: false,
      buttonType: true,
      againShow: false,
      filterOutShow: false,
      blockReasonType: null,
      blockReasonList: [],
      consumeData: {},
      blockRemark: '',
      carrierType: '',
      setGrade: '',
      titleContent: '',
      enableRemark: '',
      editLabel: {},
      timer: null,
      num: 1,
      activeKey: 1,
      carrierLevel: 0,
      carrierId: props.carrierId,
      detailData: props.detail,
      carrierData: {},
      colors: ['', '#FF831C', '#32A305', '#19D3C6', '#3470FF', '#D43030', '#E5E5E5']
    })
    const loadFilterOutReason = () => {
      filterOutReason({}).then(res => {
        if (res.code === 10000) {
          state.blockReasonList = res.data
        }
      })
    }
    const grade = () => {
      SelectionGrade({
        carrierId: props.carrierId,
        carrierLevel: {
          value: state.setGrade
        }
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.gradeShow = false
          onDetailData()
          onDominantLine()
        }
      })
    }
    const editLabel = () => {
      SelectionGrade({
        carrierId: props.carrierId,
        tagId: state.editLabel.value,
        tagName: state.editLabel.label
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.labelShow = false
        }
      })
    }
    const blacklist = () => {
      black(props.carrierId, {})
        .then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            onDetailData()
          }
        })
    }
    const labelChange = (e, v) => {
      state.editLabel = v
    }
    onMounted(() => {
      onDetailData()
      onDominantLine()
      loadFilterOutReason()
      getConsumptionData()
      componsLoad(state.activeKey)
    })
    const getConsumptionData = () => {
      consumptionData({ carrierId: state.carrierId }).then(res => {
        if (res.code === 10000 && res.data) {
          state.consumeData = res.data
        }
      })
    }
    const updateGrade = () => {
      onDetailData()
    }
    const onDetailData = () => {
      detailData(state.carrierId)
        .then(res => {
          if (res.code === 10000) {
            state.fromData = res.data
            if (res.data.isBlock.value === 1) {
              state.blackTitle = '取消拉黑承运商'
            } else {
              state.blackTitle = '拉黑承运商'
            }
          }
        }).catch(err => {
          console.log(err)
        })
    }
    const onDominantLine = () => {
      dominantLine(state.carrierId)
        .then(res => {
          if (res.code === 10000) {
            state.carrierData = res.data
          }
        }).catch(err => { console.log(err) })
    }
    const componsLoad = e => {
      setTimeout(() => {
        switch (e) {
          case 1:
            _vehicleList.value.loadData()
            break
          case 2:
            _driverList.value.loadData()
            break
          case 3:
            _contractRoute.value.loadData()
            break
          case 4:
            _financeData.value.loadData()
            break
          case 5:
            _detailData.value.loadData()
            break
          case 6:
            _addressList.value.loadData()
            break
          case 7:
            _damageList.value.loadData()
            break
          case 9:
            _accountList.value.loadData()
            break
          case 10:
            _staffList.value.loadData()
            break
          case 11:
            _contractTask.value.loadData()
            break
          case 12:
            _bindMembers.value.loadData()
            break
          case 13:
            _gradeData.value.loadData()
            break
          case 14:
            _qualityList.value.loadData()
            break
          case 15:
            _journal.value.loadData()
            break
        }
      }, 1)
    }
    const tabsChange = e => {
      if (state.status === false) {
        state.activeKey = state.num
        // message.error('操作太频繁了,请1秒后再试')
      } else {
        state.activeKey = e
        state.num = e
        componsLoad(state.activeKey)
      }
      if (state.timer === null) {
        state.status = false
        state.timer = setTimeout(() => {
          state.num = e
          state.timer = null
          state.status = true
        }, 800)
      } else {
        state.activeKey = state.num
      }
    }
    const conformFilterOut = () => {
      if (state.blockReasonType !== null) {
        state.loading = true
        filterOutAPI({
          carrierId: props.carrierId,
          blockReasonType: state.blockReasonType,
          blockRemark: state.blockRemark
        }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.filterOutShow = false
            onDetailData()
          }
        }).finally(() => { state.loading = false })
      } else message.error('请选择拉黑原因')
    }
    const openShow = () => {
      if (state.fromData.periodType?.value === 5 || state.fromData.periodType?.value === 1) {
        state.titleContent = '重新启用承运商'
      } else state.titleContent = '取消拉黑'
      state.againShow = true
    }
    const confirmActivate = () => {
      if (state.fromData.periodType?.value === 5 || state.fromData.periodType?.value === 1) {
        if (state.enableRemark.length >= 50) {
          state.loading = true
          carrierUse({
            carrierId: props.carrierId,
            enableRemark: state.enableRemark
          }).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.againShow = false
              onDetailData()
            }
          }).finally(() => {
            state.loading = false
          })
        } else message.error('原因不得少于50字')
      } else {
        if (state.enableRemark.length >= 50) {
          state.loading = true
          carrierActivate({
            blockRemark: state.enableRemark,
            carrierId: props.carrierId
          }).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.againShow = false
              onDetailData()
            }
          }).finally(() => {
            state.loading = false
          })
        } else message.error('原因不得少于50字')
      }
    }

    return {
      ...toRefs(state),
      tabsChange,
      grade,
      editLabel,
      blacklist,
      labelChange,
      updateGrade,
      _journal,
      _gradeData,
      _vehicleList,
      _driverList,
      _contractRoute,
      _financeData,
      _detailData,
      _addressList,
      _damageList,
      _accountList,
      _staffList,
      _contractTask,
      _bindMembers,
      _qualityList,
      onDetailData,
      openShow,
      conformFilterOut,
      confirmActivate
    }
  }
}
</script>

<style lang="less" scoped>
@import './common.less';
@import './index.less';
</style>
