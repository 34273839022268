<template>
  <div>
    <div style="display:flex;justify-content:flex-end;margin:10px 0">
      <a-button @click="addShow = true,title='新增地址'">+新增地址</a-button>
    </div>
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #idCard="{ record }">
        <div>
          <a @click="lookImg(record)">查看图片</a>
        </div>
      </template>
      <template #operation="{ record }">
        <div style="display: flex;justify-content: space-around;">
          <a-popconfirm title="确认删除?" ok-text="确认" cancel-text="取消" @confirm="deleteData(record)">
            <a style="color:#A30014">删除</a>
          </a-popconfirm>
          <a @click="lookEdit(record)">编辑</a>
        </div>
      </template>
    </a-table>
    <a-modal :width="750" :title="title" footer="" v-model:visible="addShow" @cancel="closeWindow">
      <Add :btnShow="false" @acceptCode='acceptCode' :detailData="detailData" ref="addRef" />
    </a-modal>
    <a-modal v-model:visible="lookShow" title="身份证照片" footer="" @cancel="clearImg">
      <div style="display: flex;justify-content: space-around;text-align: center;">
        <div>
          <div>身份证-正面</div>
          <a-image :width="200" :src="imgData.idCardFrontFileUrl" />
        </div>
        <div>
          <div>身份证-反面</div>
          <a-image :width="200" :src="imgData.idCardEndFileUrl" />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { page, deleteAddress, add, lookPhotos, detailEdit } from '@/api/carrier/addressList'
import { message } from 'ant-design-vue'
// import OptionalMap from '@/views/components/optionalMap'
import Add from '@/views/components/address'
export default {
  props: {
    carrierId: String
  },
  // components: { OptionalMap, Add },
  components: { Add },
  setup (props, context) {
    const addRef = ref(null)
    const state = reactive({
      carrier: props.carrierId,
      title: '新增地址',
      listData: [],
      pagination: {},
      addForm: {},
      detailData: {},
      imgData: {
        idCardFront: '',
        idCardEnd: '',
        idCardFrontFileUrl: '',
        idCardEndFileUrl: ''
      },
      mirrorImgData: {},
      lookShow: false,
      loading: false,
      addShow: false,
      columns: [
        {
          title: '别名',
          dataIndex: 'alias',
          align: 'center'
        },
        {
          title: '所属城市',
          dataIndex: 'address',
          align: 'center'
        },
        {
          title: '详细地址',
          dataIndex: 'addressDetails',
          align: 'center'
        },
        {
          title: '对接人',
          dataIndex: 'dockingPerson',
          align: 'center'
        },
        {
          title: '对接人电话',
          dataIndex: 'dockingMobile',
          align: 'center'
        },
        {
          title: '身份证号码',
          dataIndex: 'idNumber',
          align: 'center'
        },
        {
          title: '身份证正反面',
          dataIndex: 'idCard',
          align: 'center',
          slots: {
            customRender: 'idCard'
          }
        },
        {
          title: '创建人',
          dataIndex: 'creatorName',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '',
          slots: {
            customRender: 'operation'
          }
        }
      ]
    })
    onMounted(() => {
      state.mirrorImgData = JSON.stringify(state.imgData)
    })
    const lookEdit = record => {
      state.detailData = record
      state.title = '编辑地址'
      state.addShow = true
      setTimeout(() => {
        addRef.value.getData()
      }, 100)
    }
    // 表格数据加载
    const loadData = () => {
      state.loading = true
      page(
        props.carrierId,
        {
          current: state.pagination.current,
          size: state.pagination.pageSize
        }).then(res => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.pagination.current = res.data.current
            state.pagination.pageSize = res.data.size
            state.pagination.total = res.data.total
          }
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          state.loading = false
        })
    }
    const lookImg = record => {
      state.lookShow = true
      setTimeout(() => {
        lookPhotos({
          id: record.id
        }).then(res => {
          if (res.code === 10000) {
            if (res.data.idCardFrontFileUrl) state.imgData.idCardFrontFileUrl = res.data.idCardFrontFileUrl.url
            if (res.data.idCardEndFileUrl) state.imgData.idCardEndFileUrl = res.data.idCardEndFileUrl.url
          }
        })
      }, 100)
    }
    // 删除
    const deleteData = (e) => {
      console.log('e==', e.id)
      deleteAddress(e.id).then(res => {
        message.info(res.msg)
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        loadData()
      })
    }
    // 表格分页
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 新增地址
    const onAdd = () => {
      setTimeout(() => {
        addRef.value.open()
        // addRef.value.onData()
        add(props.carrierId, state.addForm).then(res => {
          if (res.code === 10000) {
            state.addShow = false
            message.info(res.msg)
            state.addForm = {}
            loadData()
          }
        }).catch(err => {
          console.log(err)
        })
      }, 100)
    }
    const editData = () => {
      detailEdit(state.addForm.carrierId, state.addForm).then(res => {
        if (res.code === 10000) {
          state.addShow = false
          message.info('编辑成功')
          state.addForm = {}
          loadData()
        }
      })
    }
    // 接受返回值
    const acceptCode = data => {
      if (data) {
        console.log(data);
        if (data.id) {
          state.addForm = data
          editData()
        } else {
          state.addForm = data
          onAdd()
          setTimeout(() => {
            state.addShow = false
            state.addForm = {}
            closeWindow()
          }, 500)
        }
      }
    }
    const closeWindow = () => {
      state.addShow = false
      setTimeout(() => {
        addRef.value.clearData()
      }, 20)
      loadData()
    }
    const clearImg = () => {
      state.imgData = JSON.parse(state.mirrorImgData)
    }

    return {
      ...toRefs(state),
      handleTableChange,
      loadData,
      deleteData,
      onAdd,
      lookImg,
      addRef,
      clearImg,
      lookEdit,
      acceptCode,
      closeWindow

    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>
