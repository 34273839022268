import { postBodyRequest,getRequest } from '@/utils/axios'

export function list (params) {
  return postBodyRequest('/carrier/city/line/cityLevel/List', params)
}

export function edit (params) {
  return postBodyRequest('/carrier/city/line/cityLevel/type', params)
}
export function gradeCarrier (params) {
  return getRequest('/carrier/carrier/rate/get-carrier-rate-detail', params)
}
export function gradeSave (params) {
  return postBodyRequest('/carrier/carrier/rate/save', params)
}
export function getHistoryData (params) {
  return postBodyRequest('/carrier/carrier/rate/carrier-score-record', params)
}
export function carrierRateData (params) {
  return getRequest('/anal/trans/capacity/carrier-rate-data', params)
}
export function carrierPeriod (params) {
  return postBodyRequest('/carrier/carrier/rate/carrier-period', params)
}