<template>
  <div>
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
    </a-table>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'

export default {
  props: { carrierId: String },
  setup (props, context) {
    const state = reactive({
      loading: false,
      carrierId: props.carrierId,
      listData: [],
      columns: [
        {
          title: '车辆车架号',
          dataIndex: 'vinNo',
          align: 'center'
        },
        {
          title: '质损车辆',
          dataIndex: 'damageCar',
          align: 'center'
        },
        {
          title: '责任人',
          dataIndex: 'empName',
          align: 'center'
        },
        {
          title: '金额',
          dataIndex: 'money',
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
        },
        {
          title: '质损时间',
          dataIndex: 'damageTime',
          align: 'center'
        },

      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const loadData = () => {
      state.loading = true
      setTimeout(() => {
        if (state.listData.length < 15) {
          for (let i = 0; i < 15; i++) {
            state.listData.push({
              id: i + 1,
              vinNo: '川A6X338',
              damageCar: '粤A6X338',
              empName: '小王',
              money: '999',
              remark: '备注信息',
              damageTime: '2022-04-20 12:06:45'
            })
          }
        }
        state.loading = false
      }, 300)
    }
    onMounted(() => {
      // loadData()
    })
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }

    return {
      ...toRefs(state),
      loadData,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>