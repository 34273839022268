<template>
  <div>
    <a-form layout="inline">
      <a-form-item label="">
        <a-input v-model:value="searchForm.carNo" placeholder="车牌号" />
      </a-form-item>
      <a-form-item label="">
        <div class="operate-btn">
          <a-button class="header-search-btn" @click="searchData" :loading="loading">搜索</a-button>
          <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
        </div>
      </a-form-item>
    </a-form>
    <div class="quantBox">
      <div class="m-r2">质损总台量：{{ quantitativeMsg.massLossNum }}</div>
      <div class="m-r2">质损总金额：￥{{ quantitativeMsg.massLoss }}</div>
      <div>赔付金额：￥{{ quantitativeMsg.lossIndemnity }}</div>
    </div>
    <a-table :columns="columns" size="small" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination" :loading="loading" @change="tableChange">
      <template #brand="{ record }">
        <div>
          {{ record.orderVehicleVin + ' ' + record.brand + record.model }}
        </div>
      </template>
      <template #lossTypes="{record}">
        <div>
          <span v-for="(item,index) in record.qualityLossType" :key="index">{{ item }}<span v-show="index < record.qualityLossType.length">，</span></span>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { qualityPage,qualityLoss } from '@/api/carrier/quality'

export default {
  props: {
    carrierId: String
  },
  setup (props) {
    const state = reactive({
      loading: false,
      listData: [],
      quantitativeMsg:{},
      searchForm: {
        carNo: ''
      },
      columns: [
        {
          title: '质损车辆',
          dataIndex: '',
          align: 'center',
          slots: {
            customRender: 'brand',
          }
        },
        {
          title: '订单号',
          dataIndex: 'orderId',
          align: 'center'
        },
        {
          title: '下单时间',
          dataIndex: 'orderCreateTime',
          align: 'center'
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
          align: 'center'
        },
        // {
        //   title: '运输车牌',
        //   dataIndex: '',
        //   align: 'center'
        // },
        {
          title: '质损金额',
          dataIndex: 'qualityLossAmt',
          align: 'center'
        },
        {
          title: '质损类型',
          dataIndex: '',
          align: 'center',
          slots: {
            customRender: 'lossTypes',
          }
        },
        {
          title: '是否保险理赔',
          dataIndex: 'isClaim.label',
          align: 'center'
        },
        {
          title: '赔付金额',
          dataIndex: 'amt',
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
        },
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      }
    })
    const getQualityPage = () =>{
      qualityLoss({carrierId:props.carrierId}).then(res=> {
       state.quantitativeMsg = res.data
      }).finally(()=>{})
    }
    const loadData = () => {
      state.loading = true
      qualityPage({
        objectId: props.carrierId,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        ...state.searchForm
      }).then(res => {
        console.log(res);
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          getQualityPage()
        }
      }).finally(() => { state.loading = false })
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm.carNo = ''
      searchData()
    }
    const tableChange = page => {
      state.pagination = page
      loadData()
    }
    return {
      ...toRefs(state),
      loadData,
      searchData,
      resetData,
      tableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>